
import CyanPageWrapper, {
  store,
  defineComponent,
} from "@/components/CyanPageWrapper.vue";

import { municipios } from "@/modules/cyanRegions";
import ud from "@/modules/cyanUserData.ts";

import {
  IonRow,
  IonCol,
  IonPage,
  toastController,
  IonSearchbar,
} from "@ionic/vue";

import cyanRequest from "../modules/cyanRequest";

export default defineComponent({
  name: "PuntoCyan",
  components: {
    IonRow,
    IonPage,
    IonCol,
    IonSearchbar,
    CyanPageWrapper,
  },
  setup() {
    return {};
  },
  data() {
    return {
      filtroRaw: "",
      movilizado: { ok: false },
      buscando: false,
    };
  },
  computed: {
    filtro(): string {
      const f = this.filtroRaw.replace(/[^0-9]/g, "");
      if (f.length < 9) return f;
      return f.substr(0, 8) + "-" + f.substr(8, 1);
    },
    nivel(): string {
      return (this.$route.params.ambito as string) || "";
    },
    acj() {
      const n = this.nivel as string;
      const acj = store.state.allowedCentrosJuntas[n];
      return acj || {};
    },
    idCentro(): string {
      return (this.$route.params.centro as string) || "-1";
    },

    filtroAplicable() {
      return (this as any).filtro.length == 10;
    },

    ambito() {
      const n = this.datosCentro.nivel as string;
      if (!(n in municipios)) return "";
      return municipios[n].nombre + ", " + municipios[n].nombrePadre;
    },
    datosCentro() {
      return ud("centro", { nivel: "SV", nombre: "Desconocido" });
    },
  },
  watch: {
    filtroRaw: function () {
      if (this.filtroRaw != this.filtro) {
        this.filtroRaw = this.filtro;
        const el = document.getElementById("duisearch") as any;

        if (el) {
          el.value = this.filtro; // bug en ion-input
          if (
            el.children &&
            el.children[0] &&
            el.children[0].tagName == "INPUT"
          )
            el.children[0].value = this.filtro;
        }
      }
    },
    filtro: function () {
      const _t = this as any;
      if (_t.filtroAplicable) {
        _t.buscando = true;

        cyanRequest("/pcsl/checkDUI/" + this.filtro, {
          isPost: true,
          silent: true,
          needsAuth: true,
          retries: 3,
        })
          .catch(function () {
            return { ok: false };
          })
          .then(function (r) {
            _t.movilizado = r;
            _t.buscando = false;
          });
      }
    },
  },
  methods: {
    movilizadoClick() {
      const m = this.movilizado as any;
      console.log(m);

      if (!m.ok || !m.found || !m.centroOk) return;
      if (m.movilizado === "pending") return;

      const oldEM = m.movilizado;
      m.movilizado = "pending";

      return cyanRequest(
        "pcsl/" + (oldEM ? "unsetDUI" : "setDUI") + "/" + m.dui,
        {
          isPost: true,
          needsAuth: true,
          retries: 3,
        }
      ).then(function (d: any) {
        let msg = m.nombre + " (DUI " + m.dui + ").";

        if (d.ok) {
          if (m && m.ok && m.dui && m.dui == d.dui) m.movilizado = d.movilizado;
          msg =
            "Se " +
            (d.movilizado ? "MARCÓ" : "DESMARCÓ") +
            " como participante a " +
            msg;
        } else {
          m.movilizado = oldEM;
          msg =
            "Hubo un error al actualizar el estado de " +
            msg +
            " Inténtelo de nuevo más tarde.";
        }

        toastController
          .create({
            message: msg,
            duration: 2000,
          })
          .then((toast: any) => toast.present());
        return d;
      });
    },
    async checkEnvioFiltro(e: KeyboardEvent) {
      if (
        e.keyCode != 13 ||
        this.filtro === "" ||
        !this.movilizado ||
        !this.movilizado.ok ||
        !(this.movilizado as any).found
      )
        return;

      const r = await this.movilizadoClick();
      if (r.ok) this.filtroRaw = "";
    },
  },
});
